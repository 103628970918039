p {

    color: #607a8b;
    font-size: 18px;
}

.social {
   
    font-size: 25px;
    padding-inline: 5px;
    
}

.social a {
    color: rgb(0, 0, 0);
}

.social a:hover {
    color: rgb(67, 217, 255);
}

