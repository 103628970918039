@media only screen and (max-width: 450px) {
    
    #about {
        width: 300px;
      }
    
    #sk-portfolio  {
      max-width: 400px;
    }
  
  
 
  }
  

  
