/* contacts */

#contact-button {
    border-radius: 10px;
    padding: 10px;
    backdrop-filter: blur(7px);
    display: flex;
    background-color: rgba(141, 174, 196, 0.459);
}

