.textured-logo {
    fill: url(#pattern); /* Apply the pattern as the fill */
  }
  
  /* Define the pattern */
  pattern {
    width: 20;
    height: 20;
    fill: url(#image); /* Reference the image for the pattern */
  }
  image {
    width: 20;
    height: 20;
  }
  